var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "my-0 py-0 mt-n5", attrs: { fluid: "" } },
    [
      _vm.hasPaymentMethod
        ? _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "vig-card-header-mini py-0",
                  attrs: { cols: "12" }
                },
                [_c("GameHeader", { attrs: { game: _vm.gameHeaderInfo } })],
                1
              ),
              _c(
                "v-col",
                { staticClass: "banner", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _vm.type === "line" || _vm.type === "money"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "1" },
                              on: { click: _vm.favoriteClicked }
                            },
                            [
                              _vm.favorite
                                ? _c("v-icon", { staticClass: "icon" }, [
                                    _vm._v("favorite")
                                  ])
                                : _c("v-icon", { staticClass: "icon" }, [
                                    _vm._v("favorite_border")
                                  ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-col", { staticClass: "pa-0" }, [
                        _vm.type === "line" || _vm.type === "money"
                          ? _c("h1", { staticClass: "main-team robo" }, [
                              _vm._v(_vm._s(_vm.myteam))
                            ])
                          : _c(
                              "h1",
                              {
                                staticClass: "main-team robo",
                                staticStyle: { "text-transform": "uppercase" }
                              },
                              [_vm._v(_vm._s(_vm.type))]
                            )
                      ]),
                      _vm.type === "line" || _vm.type === "money"
                        ? _c("v-col", {
                            staticClass: "pa-0",
                            attrs: { cols: "1" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
                _c("span", { staticClass: "sub-text" }, [
                  _vm._v("Limit: $" + _vm._s(_vm.betlimit))
                ]),
                _vm.vigSavings != 0
                  ? _c("span", { staticClass: "sub-text" }, [
                      _vm._v(" Vig Savings: $" + _vm._s(_vm.vigSavings))
                    ])
                  : _vm._e()
              ]),
              _vm.type === "line" || _vm.type === "money"
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", align: "center" }
                    },
                    [_c("h3", [_vm._v(_vm._s(_vm.bettype_name))])]
                  )
                : _vm._e(),
              _vm.betlimit > 0
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0",
                          attrs: { justify: "space-around", align: "center" }
                        },
                        [
                          _c("v-col", { staticClass: "text-left py-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "sumdiff-btn minus robo",
                                staticStyle: { "line-height": "0.75em" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.stepLine(
                                      _vm.bettype_stepvalue *
                                        ((_vm.type === "line" ||
                                          _vm.type === "money") &&
                                        _vm.myteam === _vm.game.awayteam
                                          ? 1
                                          : -1),
                                      _vm.maxmoneyline * -1,
                                      _vm.maxmoneyline
                                    )
                                  }
                                }
                              },
                              [_vm._v(" - ")]
                            )
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center robo bet-values py-1 px-1",
                              attrs: { cols: "4" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ml-n3",
                                  staticStyle: { "line-height": "60px" },
                                  style: {
                                    "font-size": _vm.getFontSize(
                                      _vm.form.lineamount
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.type === "line" ||
                                        _vm.type === "money"
                                        ? _vm.showSpread(
                                            _vm.form.lineamount,
                                            _vm.myteam === _vm.game.hometeam
                                              ? "home"
                                              : "away"
                                          )
                                        : _vm.form.lineamount
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("v-col", { staticClass: "text-right py-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "sumdiff-btn plus robo",
                                staticStyle: { "line-height": "0.75em" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.stepLine(
                                      _vm.bettype_stepvalue *
                                        ((_vm.type === "line" ||
                                          _vm.type === "money") &&
                                        _vm.myteam === _vm.game.awayteam
                                          ? -1
                                          : 1),
                                      _vm.maxmoneyline * -1,
                                      _vm.maxmoneyline
                                    )
                                  }
                                }
                              },
                              [_vm._v(" + ")]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0",
                          attrs: { justify: "space-around", align: "center" }
                        },
                        [
                          _c("v-col", { staticClass: "text-left py-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "sumdiff-btn minus robo",
                                staticStyle: { "line-height": "0.75em" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.stepAmount(
                                      _vm.stepvalue * -1,
                                      10,
                                      _vm.betlimit
                                    )
                                  }
                                }
                              },
                              [_vm._v(" - ")]
                            )
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center robo bet-values py-1 px-0",
                              attrs: { cols: "4" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ml-n3",
                                  staticStyle: { "line-height": "60px" },
                                  style: {
                                    "font-size": _vm.getFontSize(
                                      _vm.form.amount
                                    )
                                  }
                                },
                                [_vm._v("$" + _vm._s(_vm.form.amount))]
                              )
                            ]
                          ),
                          _c("v-col", { staticClass: "text-right py-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "sumdiff-btn plus robo",
                                staticStyle: { "line-height": "0.75em" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.stepAmount(
                                      _vm.stepvalue,
                                      10,
                                      _vm.betlimit
                                    )
                                  }
                                }
                              },
                              [_vm._v(" + ")]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.betlimit > 0
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-0",
                          attrs: { align: "center", justify: "space-between" }
                        },
                        _vm._l(_vm.steps, function(s, i) {
                          return _c(
                            "v-col",
                            { key: i, staticClass: "text-center pa-1" },
                            [
                              _c(
                                "v-btn",
                                {
                                  class:
                                    _vm.stepvalue === s
                                      ? "white--text"
                                      : "black--text",
                                  attrs: {
                                    fab: "",
                                    color:
                                      _vm.stepvalue === s
                                        ? "light-green darken-2"
                                        : "white"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.setStepValue(s)
                                    }
                                  }
                                },
                                [_vm._v("$" + _vm._s(s))]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.betlimit > 0 &&
                _vm.type === "line" &&
                  _vm.form.lineamount === _vm.game.ats_line) ||
              (_vm.type === "money" &&
                _vm.form.lineamount === _vm.game.money_line) ||
              ((_vm.type === "over" || _vm.type === "under") &&
                _vm.form.lineamount === _vm.game.ou_line)
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", justify: "center" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { justify: "center" }
                        },
                        [
                          _c("v-switch", {
                            staticClass: "ma-0 pa-0",
                            attrs: { id: "followline", "hide-details": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _vm._v(" Auto adjust to current line "),
                                      _c(
                                        "v-dialog",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              color: "black"
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("help")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            4229664213
                                          ),
                                          model: {
                                            value: _vm.lineInfo,
                                            callback: function($$v) {
                                              _vm.lineInfo = $$v
                                            },
                                            expression: "lineInfo"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "pt-3" },
                                                [
                                                  _vm._v(
                                                    " With selector turned on, until your wager is taken, it will automatically adjust to the current consensus line when a change in the line is identified. Although this feature can protect your offer in case of a dramatic line change, the use of this feature is at your own risk. VigZero uses a combination of multiple sources to get the average consensus and is not responsible for the line provided from these sources or any changes made to your wager. "
                                                  )
                                                ]
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.lineInfo = false
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Got it ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3835531669
                            ),
                            model: {
                              value: _vm.form.followline,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "followline", $$v)
                              },
                              expression: "form.followline"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", justify: "center" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0", attrs: { justify: "center" } },
                    [
                      !_vm.honorbet
                        ? _c("v-switch", {
                            staticClass: "ma-0 pa-0",
                            attrs: { id: "fullwager", "hide-details": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _vm._v(" All or Nothing "),
                                      _c(
                                        "v-dialog",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              color: "black"
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("help")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            4229664213
                                          ),
                                          model: {
                                            value: _vm.fullwagerInfo,
                                            callback: function($$v) {
                                              _vm.fullwagerInfo = $$v
                                            },
                                            expression: "fullwagerInfo"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "pt-3" },
                                                [
                                                  _vm._v(
                                                    " With selector turned on, your wager cannot be taken in partial amounts, the taker of the bet will have to take the full amount offered. VigZero by default allows offer takers to take a partial amounts of larger offers. "
                                                  )
                                                ]
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.fullwagerInfo = false
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Got it ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2157423296
                            ),
                            model: {
                              value: _vm.form.fullwager,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "fullwager", $$v)
                              },
                              expression: "form.fullwager"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.betlimit > 0
                ? _c(
                    "v-col",
                    { staticClass: "my-0 py-0" },
                    [
                      _vm.wager_id == 0
                        ? _c(
                            "v-row",
                            {
                              staticClass: "my-0 py-0",
                              attrs: { justify: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "my-0 py-0",
                                  attrs: { offset: "3" }
                                },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.form.offerto,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "offerto", $$v)
                                        },
                                        expression: "form.offerto"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        staticClass: "py-0 my-0",
                                        attrs: {
                                          value: "anyone",
                                          label: "Anyone",
                                          color: "light-green darken-2",
                                          "hide-details": ""
                                        }
                                      }),
                                      _c("v-radio", {
                                        staticClass: "py-0 my-0",
                                        attrs: {
                                          value: "group",
                                          label: "Group",
                                          color: "light-green darken-2",
                                          "hide-details": ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.betlimit > 0 &&
              _vm.form.offerto === "group" &&
              _vm.wager_id == 0
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-center my-0 py-0",
                      attrs: { cols: "12" }
                    },
                    [
                      _vm.groups && _vm.groups.length > 0
                        ? _c("v-select", {
                            attrs: {
                              label: "Groups",
                              items: _vm.groups,
                              "item-text": "name",
                              "item-value": "id",
                              "sigle-line": ""
                            },
                            model: {
                              value: _vm.form.friendgroup_id,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "friendgroup_id", $$v)
                              },
                              expression: "form.friendgroup_id"
                            }
                          })
                        : _vm._e(),
                      !_vm.groups || !_vm.groups.length
                        ? _c("p", [_vm._v(" No groups available ")])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "money"
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-1",
                      attrs: { cols: "12", align: "center" }
                    },
                    [
                      _vm.wagerIsValid
                        ? _c("h3", { staticStyle: { "font-size": "150%" } }, [
                            _vm._v(
                              "Risk $" +
                                _vm._s(_vm.form.amount) +
                                " to win $" +
                                _vm._s(_vm.takerAmount(_vm.form.amount))
                            )
                          ])
                        : _c(
                            "h3",
                            {
                              staticClass: "red--text",
                              staticStyle: { "font-size": "150%" }
                            },
                            [_vm._v(_vm._s(_vm.wagerInvalidMessage))]
                          )
                    ]
                  )
                : _vm._e(),
              _vm.betlimit > 0
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", align: "center" }
                    },
                    [
                      _vm.wager_id == 0
                        ? _c(
                            "button",
                            {
                              staticClass: "vigzero-btn robo place-btn",
                              attrs: {
                                disabled: _vm.loading || !_vm.wagerIsValid
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.createWager($event)
                                }
                              }
                            },
                            [_vm._v("Make Offer")]
                          )
                        : _vm._e(),
                      _vm.wager_id != 0
                        ? _c(
                            "button",
                            {
                              staticClass: "vigzero-btn robo place-btn",
                              attrs: {
                                disabled: _vm.loading || !_vm.wagerIsValid
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.updateWager($event)
                                }
                              }
                            },
                            [_vm._v("Update Offer")]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm.betlimit <= 0
                ? _c(
                    "v-col",
                    { staticClass: "text-center py-0", attrs: { cols: "12" } },
                    [
                      _c("p", [
                        _vm._v(
                          "Sorry, you have reached your bet limit. Be sure to confirm any outstanding payments."
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "container",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/settings")
                    }
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "card warning-card" },
                    [
                      _c("v-card-text", [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "You currently do not have a payment method setup. In order to make or take a wager you must first setup a payment method in the settings page. Or click here to go there now."
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showDepositDialog,
            callback: function($$v) {
              _vm.showDepositDialog = $$v
            },
            expression: "showDepositDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4 mt-4" },
            [
              _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "headline font-weight-bold" }, [
                    _vm._v("Initiate Deposit")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    _vm._b(
                      {
                        staticClass: "close-icon",
                        on: { click: _vm.closeDialog }
                      },
                      "v-icon",
                      _vm.$attrs,
                      false
                    ),
                    [_vm._v(" mdi-close ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("strong", [
                    _vm._v(
                      "Your VZ account balance is insufficient to offer this wager."
                    )
                  ]),
                  _vm._v(
                    " VigZero not only allows you to keep more money with zero vigorish, for the 2024 football season, the 2% deposit fee is waived (no withdrawal fees ever with VigZero). "
                  ),
                  _c("br"),
                  _c("br"),
                  _c("strong", [_vm._v("How to fund your VigZero account:")]),
                  _c("ol", [
                    _c("li", [
                      _vm._v(
                        "Below, provide the amount you intend on depositing using Venmo."
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "After 'Submit', send the amount via Venmo to @vigzero (Venmo should open for convenience). If the amount is not received/confirmed by gametime any wagers covered by the deposit amount will be voided."
                      )
                    ])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      label: "Intended Deposit Amount",
                      prefix: "$",
                      type: "number",
                      rules: [
                        function(v) {
                          return !!v || "This field is required"
                        },
                        function(v) {
                          return v > 0 || "Enter a positive number"
                        }
                      ]
                    },
                    model: {
                      value: _vm.depositAmount,
                      callback: function($$v) {
                        _vm.depositAmount = $$v
                      },
                      expression: "depositAmount"
                    }
                  }),
                  _vm.VZBalace === 0
                    ? _c("v-checkbox", {
                        staticClass: "text-link",
                        staticStyle: { color: "#E53935", cursor: "pointer" },
                        attrs: {
                          label:
                            "I do not plan on funding my VigZero account and prefer to make this wager using the post wager honor system directly with the opposing side."
                        },
                        model: {
                          value: _vm.honorSystemChecked,
                          callback: function($$v) {
                            _vm.honorSystemChecked = $$v
                          },
                          expression: "honorSystemChecked"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.submitDeposit()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  ),
                  _c("br"),
                  _c("br")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }