<template>
  <div>
    <v-app>
      <v-app-bar
        app
        dense
        dark
        width="100%"
        max-width="600px"
        absolute
      >
        <v-app-bar-nav-icon @click="menuVisible = true">
          <v-icon>menu</v-icon>
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-menu offset-y v-model="menuOpen">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row class="my-0 pb-0 pt-2 ml-n4">
                <v-col
                  cols="12"
                  class="py-0 text-center"
                >
                  <img
                    :src="getLogo()"
                    class="logo mt-n1"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-0 text-center"
                >
                  <div
                    class="sub-text white--text mt-n1 ml-2"
                    v-show="vigsavings != 0"
                  >${{ vigsavings }} saved and counting</div>
                </v-col>
              </v-row>
            </div>
          </template>
          <v-list>
            <v-list-item to="/dashboard/schedule" class="menu-item">
              <v-icon class="menu-icon" size="32">$schedule</v-icon> Schedule
            </v-list-item>
            <v-list-item to="/dashboard/pending" class="menu-item">
              <v-icon class="menu-icon" size="32">$pending</v-icon> Pending
            </v-list-item>
            <v-list-item to="/dashboard/bestvalue" class="menu-item">
              <v-icon class="menu-icon" style="margin-left:6px" size="32">$bestvalue</v-icon> <div style="margin-left:-6px">Open Offers</div>
            </v-list-item>
            <v-list-item to="/dashboard/group" class="menu-item">
              <v-icon class="menu-icon" size="32">$group</v-icon> My Groups / Contests
            </v-list-item>
            <v-list-item to="/dashboard/favorite" class="menu-item">
              <v-icon class="menu-icon" size="32">$myteams</v-icon> My Teams
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <div v-if="!username">
          <v-btn
            class="mr-0"
            outlined
            color="white"
            to="/login"
          >Login</v-btn>
        </div>
        <div v-if="username">
          <v-row class="py-0 my-0">
            <v-col class="py-0 my-0" cols="5">
              <div class="balance-display mt-4">{{ formatAmount(userBalance) }}</div>
            </v-col>
            <v-col class="py-0 my-0" cols="7">
              <v-menu
                left
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-avatar v-if="avatar"><img :src="avatar" /></v-avatar>
                    <v-icon v-else>account_circle</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    to="/settings"
                    class="menu-item"
                  >
                    <v-icon class="menu-icon">settings</v-icon> Settings
                  </v-list-item>
                  <v-list-item
                    v-if="!isiOS()"
                    v-show="isSubscribed == false && !notificationsBlocked"
                    @click.prevent="subscribe()"
                  >
                    <v-icon class="menu-icon">notifications_active</v-icon> Enable Notifications
                  </v-list-item>
                  <v-list-item
                    v-if="!isiOS()"
                    v-show="isSubscribed == true"
                    @click.prevent="unsubscribe()"
                  >
                    <v-icon class="menu-icon">notifications_off</v-icon> Disable Notifications
                  </v-list-item>
                  <v-list-item
                    v-show="installBtn == true"
                    @click.prevent="installer()"
                  >
                    <v-icon class="menu-icon">get_app</v-icon> Install App
                  </v-list-item>
                  <v-list-item @click.prevent="logout()">
                    <v-icon class="menu-icon">exit_to_app</v-icon> Logout
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </v-app-bar>
      <v-navigation-drawer
        v-model="menuVisible"
        absolute
        temporary
      >
        <v-list>
          <v-list-item
            class="menu-item"
            to="/"
            v-if="username"
          >
            <v-icon class="menu-icon">home</v-icon>
            <span class="v-list-item-text">Home</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/"
            v-else
          >
            <v-icon class="menu-icon">home</v-icon>
            <span class="v-list-item-text">Dashboard</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/account"
            v-if="username"
          >
            <v-icon class="menu-icon">mdi-currency-usd</v-icon>
            <span class="v-list-item-text">Account</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/transactions"
            v-if="username"
          >
            <v-icon class="menu-icon">history</v-icon>
            <span class="v-list-item-text">Transaction History</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/wagerhistory"
            v-if="username"
          >
            <v-icon class="menu-icon">history</v-icon>
            <span class="v-list-item-text">Wager History</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/groups"
            v-if="username"
          >
            <v-icon class="menu-icon">group</v-icon>
            <span class="v-list-item-text">Group Manager</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/invite"
            v-if="username && allowInvite()"
          >
            <v-icon class="menu-icon">person_add</v-icon>
            <span class="v-list-item-text">Invite Friends</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/disputes"
            v-if="username"
          >
            <v-icon class="menu-icon">report</v-icon>
            <span class="v-list-item-text">Disputes</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/notifications"
            v-if="username"
          >
            <v-icon class="menu-icon">notifications</v-icon>
            <span class="v-list-item-text">Notifications</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/about"
          >
            <v-icon class="menu-icon">info</v-icon>
            <span class="v-list-item-text">About</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/faq"
          >
            <v-icon class="menu-icon">help</v-icon>
            <span class="v-list-item-text">FAQ</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to="/contact"
          >
            <v-icon class="menu-icon">email</v-icon>
            <span class="v-list-item-text">Contact Us</span>
          </v-list-item>
          <v-list-item
            class="menu-item"
            to=""
            @click="openslides()"
          >
            <v-icon class="menu-icon">visibility</v-icon>
            <span class="v-list-item-text">View Tutorial</span>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            class="menu-item"
            href="https://www.ncpgambling.org/help-treatment/"
            target="_blank"
          >
            <v-icon class="menu-icon">priority_high</v-icon>
            <span class="v-list-item-text">Responsible Gambling</span>
          </v-list-item>
          <div class="text-center">
            <span style="font-size: 12px;">Gamblers Anonymous | 1-800-GAMBLER</span>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container fluid class="pb-0 my-0">
          <slot></slot>
          <!--<footer-bar></footer-bar>-->
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
// '@' sign means src
// import NavBar from '@/components/NavBar'
// import FooterBar from '@/components/FooterBar'
import UserService from '@/services/UserService'
import NotificationsService from '@/services/NotificationsService'
import logo from '@/assets/white_red_logo/512.png'
// import logo from '@/assets/logos/vz-xmas-2-512.png'

import WagerService from '@/services/WagerService'
import { bus } from '@/main'
// import UserStats from '@/components/UserStats'

export default {
  components: {
    // NavBar,
    // FooterBar
    // UserStats
  },
  data () {
    return {
      isSubscribed: false,
      notificationsBlocked: false,
      menuVisible: false,
      userMenuVisible: false,
      mytitle: this.$route.meta.title,
      username: null,
      avatar: null,
      applicationServerPublicKey: 'BClJqsmhnxlgbxNkYrlWc2wDXPD_AOoL9sisYXgCxp_JbaHG6xiIADYOBcV13t-30ndFf79gk_e7QWisw7J0-Yw',
      installBtn: false,
      showStats: false,
      vigsavings: 0,
      scheduleScroll: 0,
      menuOpen: false,
      userBalance: 0.00
    }
  },
  watch: {
    // update material toolbar title based on route meta data
    '$route.meta.title': function (value) {
      this.mytitle = value
      // force a username check
      this.assignUsername()
      this.checkSubscription()
      this.checkAvatar()

      /*
        when the location/title changes to something that
        isn't the dashboard or the wagers page,
        clear our schedule scroll location
      */
      var keepScroll = ['Dashboard', 'Wagers']
      if (!keepScroll.includes(value)) {
        this.scheduleScroll = 0
      }
    }
  },
  created: function () {
    this.checkVigSavings()
    this.fetchUserBalance()
    // Fetch the initial user balance from local storage
    const storedUser = JSON.parse(localStorage.getItem('user'))
    this.userBalance = storedUser?.info?.VZBalance || '0.00'

    // Listen for balance update events from the event bus
    bus.$on('userBalanceUpdated', (newBalance) => {
      this.userBalance = newBalance
    })

    // listen for event to set our schedule scroll variable
    bus.$on('set_schedule_scroll', (data) => {
      this.scheduleScroll = data
    })
    // listen for event to get our schedule scroll variable
    bus.$on('get_schedule_scroll', (data) => {
      // send event to give our scroll variable
      bus.$emit('schedule_scroll', this.scheduleScroll)
    })
    bus.$on('clear_schedule_scroll', (data) => {
      this.scheduleScroll = 0
    })

    bus.$on('close_menu', (data) => {
      this.menuOpen = false
    })
  },
  methods: {
    getLogo: function () {
      return logo
    },
    openslides: function () {
      bus.$emit('openslide')
    },
    logout: function () {
      const self = this
      UserService.logout()
      this.clearUsername()
      this.username = null
      self.$router.push('/login', function () {
        self.$notify({
          group: 'notices',
          title: 'Logout Complete',
          text: 'You are now logged out.',
          type: 'alert-success'
        })
      })
    },
    fetchUserBalance () {
      const userId = this.myUserId
      if (userId) {
        UserService.getUserBalance(userId).then(response => {
          this.userBalance = response.data.toFixed(2)
        }).catch(err => {
          console.error('Error fetching user balance:', err)
        })
      }
    },
    formatAmount (amount) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
    },
    checkVigSavings: function () {
      this.$updateloading(1)
      WagerService.getAllVigSavings().then(
        (response) => {
          this.vigsavings = response.data.savings
        },
        (err) => {
          console.log(err)
        }
      ).finally(this.$updateloading(-1))
    },
    checkAvatar: function () {
      try {
        var data = JSON.parse(localStorage.avatar)
      } catch {
        data = false
      }
      if (!data) {
        this.avatar = null
        return
      }
      if (data.thumbnails && data.thumbnails.small) {
        this.avatar = data.thumbnails.small
      } else {
        this.avatar = data.avatar // use the full size image, thumbnails were not created
      }
      setTimeout(() => {
        this.checkAvatar()
      }, 30000)
    },
    assignUsername: function () {
      const data = UserService.getUser()
      if (!data) {
        this.username = null
        return
      }
      this.username = data.nickname
      setTimeout(() => {
        this.assignUsername()
      }, 30000)
    },
    clearUsername: function () {
      this.username = null
    },
    showUnblock: function () {
      this.$bvModal.show('unblock-notifications-instructions')
    },
    checkSubscription: function () {
      if ('serviceWorker' in navigator) {
        // alternatively Notification.permission
        navigator.serviceWorker.getRegistration().then(registration => {
          if (registration && typeof registration.pushManager !== 'undefined') {
            registration.pushManager.getSubscription().then(subscription => {
              if (subscription === null) {
                this.isSubscribed = false
                this.checkblocked()
              } else {
                const user = UserService.getUser()
                if (user) {
                  NotificationsService.getdevice(subscription)
                    .then(response => {
                      if (response.data.deviceregistered) {
                        this.isSubscribed = true
                      } else {
                        this.isSubscribed = false
                      }
                    })
                } else {
                  this.isSubscribed = false
                }
              }
            })
          }
        })
      }
    },
    checkblocked: function () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.permissionState({
            userVisibleOnly: true,
            applicationServerKey: this.applicationServerPublicKey
          }).then(state => {
            if (state === 'denied') {
              this.notificationsBlocked = true
            }
          })
        })
      }
    },
    subscribe: function () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.applicationServerPublicKey
          }).then(async subscription => {
            if (UserService.getUserObject()) {
              const header = await UserService.authHeader()
              // post to server
              fetch(UserService.baseUrl() + 'notifications/subscribe', {
                method: 'post',
                body: JSON.stringify(subscription),
                headers: header
              })
              this.isSubscribed = true
            }
          })
        })
      }
    },
    unsubscribe: function () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration.pushManager.getSubscription().then(subscription => {
            subscription.unsubscribe().then(async old => {
              if (UserService.getUserObject()) {
                const header = await UserService.authHeader()
                fetch(UserService.baseUrl() + 'notifications/unsubscribe', {
                  method: 'post',
                  body: JSON.stringify(subscription),
                  headers: header
                })
                this.isSubscribed = false
              }
            })
          })
        })
      }
    },
    installer: function () {
      this.installBtn = false
      if (!this.installPrompt) {
        return
      }
      this.installPrompt.prompt()
      this.installPrompt.userChoice.then(result => {
        if (result.outcome === 'accepted') {
          console.log('User accepted!')
        } else {
          console.log('User denied')
        }
        this.installPrompt = null
      })
    },
    showInstallPwaBtn: function () {
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        this.installPrompt = e
        this.installBtn = true
      })
    },
    checkPWAinstalled: function () {
      // this.$gtag.customMap({ dimension1: 'pwa_status' })
      // if (window.matchMedia('(display-mode: standalone)').matches && this.installBtn === false) {
      //   this.$gtag.event('pwa_status_dimension', {
      //     pwa_status: 'installed',
      //     event_category: 'pwa_status_dimension'
      //   })
      // } else {
      //   this.$gtag.event('pwa_status_dimension', {
      //     pwa_status: 'notinstalled',
      //     event_category: 'pwa_status_dimension'
      //   })
      // }
    },
    isiOS: function () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    },
    allowInvite: function () {
      const user = UserService.getUser()
      return (user.allowinvite === 1 || user.allowinvite === '1')
    },
    to: function (route) {
      this.$router.push(route)
    }
  },
  mounted: function () {
    this.fetchUserBalance()
    this.checkSubscription()
    this.assignUsername()
    this.checkAvatar()
    this.showInstallPwaBtn()
    this.checkPWAinstalled()
  },
  computed: {
    myUserId: function () {
      const user = UserService.getUserObject()
      return user && user.info ? +user.info.id : 0
    }
  },
  filters: {
    /* currency: function (val) {
      return '$' + val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    } */
  }
}
</script>

<style lang="scss" scoped>
/* this makes fixed/reveal work */
.md-app {
  max-height: 100vh;
}

.balance-display {
  color: white;
  font-size: 16px;
  margin-right: 16px;
  display: inline-block;
}
</style>
