var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "custom-dialog",
      attrs: { "max-width": "320px", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v("Enter Amount")
          ]),
          _c(
            "v-card-text",
            [
              _vm._v(
                " Please enter the amount you have sent via Venmo and submit to review funds. "
              ),
              _c("v-text-field", {
                staticClass: "mt-4",
                attrs: {
                  label: "Enter Amount",
                  "prepend-icon": "attach_money",
                  rules: [_vm.validateAmount && _vm.validateMoney]
                },
                on: { input: _vm.formatAmount, blur: _vm.fixCents },
                model: {
                  value: _vm.displayAmount,
                  callback: function($$v) {
                    _vm.displayAmount = $$v
                  },
                  expression: "displayAmount"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", text: "" },
                  on: { click: _vm.submit }
                },
                [_vm._v("Submit")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "red darken-1", text: "" },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }