<template>
  <v-row
    class="mt-0 mb-4 py-0"
    v-if="game && game.id"
  >
    <v-col
      cols="12"
      class="py-0 datetime vig-card-header-mini"
    >
      <span class="sub-text pull-left">{{ game.league }} - {{ game.sport }}</span>
      <span class="sub-text pull-right">
        {{ game.gamedate_formatted }} {{ game.starttime_formatted }}
        <span v-if="game.broadcast">{{ game.broadcast }}</span>
      </span>
    </v-col>

    <v-col
      cols="12"
      class="mt-n2 py-0"
    >
      <v-row>
        <v-col class="py-0">
          <button
            v-if="game.bettype_ps"
            class="vigzero-btn schedule-btn"
            :class="(/* isSpecialUser && */ game.wagersaway > 0) ? 'openoffers' : ''"
            @click.prevent="chooseWager(game.id, game.awayteam_id, 'line')"
          >
            <span class="pull-left">{{ game.awayteam }} <span v-if="game.favorite && (game.favoriteaway || game.commonteamaway)">
                <v-icon class="icon text-red">{{ (game.favoriteaway) ? 'favorite' : 'flag' }}</v-icon>
              </span></span>
            <span class="pull-right">{{ showSpread(game.ats_line, 'away') }}</span>
          </button>

          <button
            v-if="game.bettype_ml && !game.bettype_ps"
            class="vigzero-btn schedule-btn"
            :class="(/* isSpecialUser && */ game.moneyaway > 0) ? 'openoffers' : ''"
            @click.prevent="chooseWager(game.id, game.awayteam_id, 'money', game.money_line * -1)"
            :disabled="!moneyLineIsValid(game) && game.moneyaway == 0"
          >
            <span class="pull-left">{{ game.awayteam }} <span v-if="game.favorite && (game.favoriteaway || game.commonteamaway)">
                <v-icon class="icon text-red">{{ (game.favoriteaway) ? 'favorite' : 'flag' }}</v-icon>
              </span></span>
            <span class="pull-right">{{ showSpread(game.money_line, 'away') }}</span>
          </button>
        </v-col>

        <v-col
          cols="2"
          class="py-0 px-1"
          v-if="game.bettype_ps && game.bettype_ml && (moneyLineIsValid(game) || moneyLineHasOpenWagers(game))"
        >
          <button
            class="vigzero-btn schedule-btn over-under-btn"
            :class="(/* isSpecialUser && */ game.moneyaway > 0) ? 'openoffers' : ''"
            @click.prevent="chooseWager(game.id, game.awayteam_id, 'money', game.money_line * -1)"
            :disabled="!moneyLineIsValid(game) && game.moneyaway == 0"
          >
            {{ showSpread(game.money_line, 'away') }}
          </button>
        </v-col>

        <v-col
          cols="2"
          class="py-2 px-1"
          v-if="game.bettype_ou"
        >
          <div
            @click.prevent="chooseWager(game.id, game.hometeam_id, 'over', game.ou_line)"
            class="oudiv"
            :style="'background-image: url(\'/img/dashboard/' + ((isSpecialUser && game.wagersover > 0) ? 'red' : 'grey') + '_arrow_up.png\')'"
          >
            <div
              style="transform: translateY(5px)"
              :style="getOUStyle(isSpecialUser, game.wagersover, game.ou_line)"
            >
              {{ (game.ou_line) ? game.ou_line : 'N/A' }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      class="mt-n2 py-0"
    >
      <v-row class="my-0 py-0">
        <v-col class="py-0">
          <button
            v-if="game.bettype_ps"
            class="vigzero-btn schedule-btn"
            :class="(/* isSpecialUser && */ game.wagershome > 0) ? 'openoffers' : ''"
            @click.prevent="chooseWager(game.id, game.hometeam_id, 'line')"
          >
            <span class="pull-left">{{ game.hometeam }} <span v-if="game.favorite && (game.favoritehome || game.commonteamhome)">
                <v-icon class="icon text-red">{{ (game.favoritehome) ? 'favorite' : 'flag' }}</v-icon>
              </span></span>
            <span class="pull-right">{{ showSpread(game.ats_line, 'home') }}</span>
          </button>

          <button
            v-if="game.bettype_ml && !game.bettype_ps"
            class="vigzero-btn schedule-btn"
            :class="(/* isSpecialUser && */ game.moneyhome > 0) ? 'openoffers' : ''"
            @click.prevent="chooseWager(game.id, game.hometeam_id, 'money', game.money_line)"
            :disabled="!moneyLineIsValid(game) && game.moneyhome == 0"
          >
            <span class="pull-left">{{ game.hometeam }} <span v-if="game.favorite && (game.favoritehome || game.commonteamhome)">
                <v-icon class="icon text-red">{{ (game.favoritehome) ? 'favorite' : 'flag' }}</v-icon>
              </span></span>
            <span class="pull-right">{{ showSpread(game.money_line, 'home') }}</span>
          </button>
        </v-col>

        <v-col
          cols="2"
          class="py-0 px-1"
          v-if="game.bettype_ps && game.bettype_ml && (moneyLineIsValid(game) || moneyLineHasOpenWagers(game))"
        >
          <button
            class="vigzero-btn schedule-btn over-under-btn"
            :class="(/* isSpecialUser && */ game.moneyhome > 0) ? 'openoffers' : ''"
            @click.prevent="chooseWager(game.id, game.hometeam_id, 'money', game.money_line)"
            :disabled="!moneyLineIsValid(game) && game.moneyhome == 0"
          >
            {{ showSpread(game.money_line, 'home') }}
          </button>
        </v-col>

        <v-col
          cols="2"
          class="py-2 px-1"
          v-if="game.bettype_ou"
        >
          <div
            @click.prevent="chooseWager(game.id, game.awayteam_id, 'under', game.ou_line)"
            class="oudiv"
            :style="'background-image: url(\'/img/dashboard/' + ((isSpecialUser && game.wagersunder > 0) ? 'red' : 'grey') + '_arrow_down.png\')'"
          >
            <div
              :style="getOUStyle(isSpecialUser, game.wagersunder, game.ou_line)"
              style="transform: translateY(-2px)"
            >
              {{ (game.ou_line) ? game.ou_line : 'N/A' }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import GameService from '@/services/GameService'
// import UserService from '@/services/UserService'

export default {
  name: 'GameSummary',
  props: {
    game: Object
  },
  computed: {
    isSpecialUser: function () {
      // currently show red for all users if games exist
      return true
      // return (+UserService.getUser().allowinvite > 0)
    },
    cancelled: function () {
      return this.game.starttime === this.game.endtime
    }
  },
  methods: {
    chooseWager (gameId, teamId, bettype, line) {
      if ((bettype === 'over' || bettype === 'under') && !line) {
        alert('This game does not currently have an OU line set. Lines are updated frequently. Please check back soon.')
      } else if (bettype === 'money' && !line) {
        alert('This game does not currently have a money line set. Lines are updated frequently. Please check back soon.')
      } else {
        this.$router.push({ name: 'wager', params: { game_id: gameId, team_id: teamId, type: bettype } })
      }
    },

    moneyLineHasOpenWagers (game) {
      return (game.moneyaway > 0 || game.moneyhome > 0)
    },

    moneyLineIsValid (game) {
      return (game.money_line !== null && game.money_line <= game.max_money_line && game.money_line >= game.max_money_line * -1)
    },

    getBoxScoreTotal (scores) {
      let total = 0
      scores.forEach(function (s) {
        total += +s
      })
      return total
    },
    getTotalOffers () {
      return +this.game.wager
    },
    showSpread (line, team) {
      return GameService.showSpread(line, team)
    },
    getOUStyle (specialuser, wagercount, line) {
      return {
        color: specialuser && wagercount > 0 ? 'white' : 'black',
        'font-size': ('' + line).length > 3 ? '0.83em' : '0.93em'
      }
    }
  }
}
</script>
<style scoped>
.game-summary-box {
  padding-top: 4px;
  padding-bottom: 4px;
}
.game-summary-box.left {
  padding-left: 16px;
}
.game-summary-box.right {
  padding-right: 16px;
}
.double-height {
  line-height: 3em;
}
.game-button {
  width: 100% !important;
  margin-bottom: 4px !important;
  background: #ddd !important;
  color: black !important;
}
.game-button >>> .md-button-content {
  width: 100% !important;
}
.oudiv {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: 100% 100%;
}
</style>
