<template>
  <v-container
    fluid
    class="my-0 py-0 mt-n5"
  >
    <v-row
      class="my-0"
      v-if="hasPaymentMethod"
    >
      <v-col
        cols="12"
        class="vig-card-header-mini py-0"
      >
        <GameHeader :game="gameHeaderInfo"></GameHeader>
      </v-col>
      <v-col
        cols="12"
        class="banner"
      >
        <v-row class="ma-0">
          <v-col
            v-if="type === 'line' || type === 'money'"
            @click="favoriteClicked"
            class="pa-0"
            cols="1"
          >
            <v-icon
              class="icon"
              v-if="favorite"
            >favorite</v-icon>
            <v-icon
              class="icon"
              v-else
            >favorite_border</v-icon>
          </v-col>
          <v-col class="pa-0">
            <h1
              v-if="type === 'line' || type === 'money'"
              class="main-team robo"
            >{{ myteam }}</h1>
            <h1
              v-else
              class="main-team robo"
              style="text-transform: uppercase;"
            >{{ type }}</h1>
          </v-col>
          <v-col
            v-if="type === 'line' || type === 'money'"
            class="pa-0"
            cols="1"
          ></v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        class="py-0"
      >
        <span class="sub-text">Limit: ${{ betlimit }}</span>
        <span
          class="sub-text"
          v-if="vigSavings != 0"
        > Vig Savings: ${{ vigSavings }}</span>
      </v-col>

      <v-col cols="12" class="py-0" v-if="type === 'line' || type === 'money'" align="center">
        <h3>{{ bettype_name }}</h3>
      </v-col>

      <v-col
        cols="12"
        class="py-0"
        v-if="betlimit > 0"
      >
        <v-row
          justify="space-around"
          align="center"
          class="my-0"
        >
          <v-col class="text-left py-1">
            <button
              class="sumdiff-btn minus robo"
              style="line-height: 0.75em"
              @click.prevent="stepLine(bettype_stepvalue * (((type === 'line' || type === 'money') && myteam === game.awayteam) ? 1 : -1), maxmoneyline * -1, maxmoneyline)"
            >
              -
            </button>
          </v-col>
          <v-col
            cols="4"
            class="text-center robo bet-values py-1 px-1"
          >
            <div
              class="ml-n3"
              style="line-height:60px"
              :style="{ 'font-size': getFontSize(form.lineamount) }"
            >{{ (type === 'line' || type === 'money') ? showSpread(form.lineamount, (myteam === game.hometeam) ? 'home' : 'away') : form.lineamount }}</div>
          </v-col>
          <v-col class="text-right py-1">
            <button
              class="sumdiff-btn plus robo"
              style="line-height: 0.75em"
              @click.prevent="stepLine(bettype_stepvalue * (((type === 'line' || type === 'money') && myteam === game.awayteam) ? -1 : 1), maxmoneyline * -1, maxmoneyline)"
            >
              +
            </button>
          </v-col>
        </v-row>
        <v-row
          justify="space-around"
          align="center"
          class="my-0"
        >
          <v-col class="text-left py-1">
            <button
              class="sumdiff-btn minus robo"
              style="line-height: 0.75em"
              @click.prevent="stepAmount(stepvalue * -1, 10, betlimit)"
            >
              -
            </button>
          </v-col>
          <v-col
            cols="4"
            class="text-center robo bet-values py-1 px-0"
          >
            <div
              class="ml-n3"
              style="line-height:60px"
              :style="{ 'font-size': getFontSize(form.amount) }"
            >${{ form.amount }}</div>
          </v-col>
          <v-col class="text-right py-1">
            <button
              class="sumdiff-btn plus robo"
              style="line-height: 0.75em"
              @click.prevent="stepAmount(stepvalue, 10, betlimit)"
            >
              +
            </button>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
        v-if="betlimit > 0"
      >
        <v-row
          class="mt-0"
          align="center"
          justify="space-between"
        >
          <v-col
            class="text-center pa-1"
            v-for="(s, i) in steps"
            :key="i"
          >
            <v-btn
              fab
              :color="(stepvalue === s) ? 'light-green darken-2' : 'white'"
              :class="(stepvalue === s) ? 'white--text' : 'black--text'"
              @click.prevent="setStepValue(s)"
            >${{ s }}</v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        justify="center"
        class="py-0"
        v-if="betlimit > 0 && (type === 'line' && form.lineamount === game.ats_line) || (type === 'money' && form.lineamount === game.money_line) || ((type === 'over' || type === 'under') && form.lineamount === game.ou_line)"
      >
        <v-row
          justify="center"
          class="ma-0 pa-0"
        >
          <v-switch
            v-model="form.followline"
            id="followline"
            class="ma-0 pa-0"
            hide-details
          >
            <template v-slot:label>
              Auto adjust to current line
              <v-dialog v-model="lineInfo">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="black"
                  >
                    <v-icon>help</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="pt-3">
                    With selector turned on, until your wager is taken, it will automatically adjust to the current consensus line when
                    a change in the line is identified. Although this feature can protect your offer in case of a dramatic line change, the use of this feature
                    is at your own risk. VigZero uses a combination of multiple sources to get the average consensus and is not responsible for the line provided
                    from these sources or any changes made to your wager.
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="lineInfo=false"
                    >
                      Got it
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-switch>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        justify="center"
        class="py-0"
      >
        <v-row
          justify="center"
          class="ma-0 pa-0"
        >
          <v-switch
            v-model="form.fullwager"
            id="fullwager"
            class="ma-0 pa-0"
            hide-details
            v-if="!honorbet"
          >
            <template v-slot:label>
              All or Nothing
              <v-dialog v-model="fullwagerInfo">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="black"
                  >
                    <v-icon>help</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="pt-3">
                    With selector turned on, your wager cannot be taken in partial amounts, the taker of the bet will have to take the full amount offered. VigZero by default allows offer takers to take a partial amounts of larger offers.
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="fullwagerInfo=false"
                    >
                      Got it
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-switch>
        </v-row>
      </v-col>

      <v-col
        v-if="betlimit > 0"
        class="my-0 py-0"
      >
        <v-row
          justify="center"
          class="my-0 py-0"
          v-if="wager_id == 0"
        >
          <v-col
            class="my-0 py-0"
            offset="3"
          >
            <v-radio-group
              v-model="form.offerto"
              row
            >
              <v-radio
                value="anyone"
                label="Anyone"
                color="light-green darken-2"
                hide-details
                class="py-0 my-0"
              ></v-radio>
              <v-radio
                value="group"
                label="Group"
                color="light-green darken-2"
                hide-details
                class="py-0 my-0"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        class="text-center my-0 py-0"
        v-if="betlimit > 0 && form.offerto === 'group' && wager_id == 0"
      >
        <v-select
          v-if="groups && groups.length > 0"
          v-model="form.friendgroup_id"
          label="Groups"
          :items="groups"
          item-text="name"
          item-value="id"
          sigle-line
        ></v-select>
        <p v-if="!groups || !groups.length">
          No groups available
        </p>
      </v-col>

      <v-col
        cols="12"
        class="py-1"
        v-if="type === 'money'"
        align="center"
      >
        <h3 v-if="wagerIsValid" style="font-size: 150%;">Risk ${{ form.amount }} to win ${{ takerAmount(form.amount) }}</h3>
        <h3 v-else style="font-size: 150%;" class="red--text">{{ wagerInvalidMessage }}</h3>
      </v-col>

      <v-col
        cols="12"
        class="py-0"
        v-if="betlimit > 0"
        align="center"
      >
        <button
          v-if="wager_id == 0"
          class="vigzero-btn robo place-btn"
          @click.prevent="createWager"
          :disabled="loading || !wagerIsValid"
        >Make Offer</button>
        <button
          v-if="wager_id != 0"
          class="vigzero-btn robo place-btn"
          @click.prevent="updateWager"
          :disabled="loading || !wagerIsValid"
        >Update Offer</button>
      </v-col>

      <v-col
        cols="12"
        class="text-center py-0"
        v-if="betlimit <= 0"
      >
        <p>Sorry, you have reached your bet limit. Be sure to confirm any outstanding payments.</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        class="container"
        @click="$router.push('/settings')"
      >
        <v-card class="card warning-card">
          <v-card-text>
            <p>
              <b>You currently do not have a payment method setup.
                In order to make or take a wager you must first setup a payment method in the settings page.
                Or click here to go there now.</b>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showDepositDialog" persistent max-width="600px" style="overflow: hidden;">
      <v-card class="pa-4 mt-4">
        <v-card-title>
          <span class="headline font-weight-bold">Initiate Deposit</span>
          <!-- Close Icon Button -->
          <v-spacer></v-spacer>
          <v-icon
            class="close-icon"
            @click="closeDialog"
            v-bind="$attrs"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <strong>Your VZ account balance is insufficient to offer this wager.</strong>
          VigZero not only allows you to keep more money with zero vigorish, for the 2024 football season, the 2% deposit fee is waived (no withdrawal fees ever with VigZero).
          <br><br>
          <strong>How to fund your VigZero account:</strong>
          <ol>
            <li>Below, provide the amount you intend on depositing using Venmo.</li>
            <li>After 'Submit', send the amount <b>via Venmo to @VigZero</b> (Venmo should open for convenience).  If the amount is not received/confirmed by gametime any wagers covered by the deposit amount will be voided.</li>
          </ol>
          <br>
          <v-text-field v-model="depositAmount"
            label="Intended Deposit Amount"
            prefix="$"
            type="number"
            outlined
            :step="50"
            :rules="[v => !!v || 'This field is required', v => v > 0 || 'Enter a positive number']"
          ></v-text-field>
          <!-- Checkbox for Honor System -->
          <v-checkbox
            v-model="honorSystemChecked"
            style="color: #E53935; cursor: pointer;"
            class="text-link"
            v-if="VZBalace === 0 && depositAmount <= 0"
            label="I do not plan on funding my VigZero account and prefer to make this wager using the post wager honor system directly with the opposing side."
          ></v-checkbox>
          <v-btn color="green" dark @click="submitDeposit()">Submit</v-btn><br><br>
          <!-- <v-btn color="red darken-1" text @click="closeDialog()">Make offer on honor system</v-btn> -->
          <!-- <a href="#" class="text-link" @click="makeOfferOnHonorSystem" style="color: #E53935; cursor: pointer;" v-if="VZBalace === 0">I do not plan on funding my VigZero account and prefer to make this wager using the post wager honor system directly with the opposing side.</a> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import GameService from '@/services/GameService'
import WagerService from '@/services/WagerService'
import FavoriteService from '@/services/FavoriteService'
import GameHeader from '@/components/games/Header'
import TransactionService from '@/services/TransactionService'
import { bus } from '@/main'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  numeric
} from 'vuelidate/lib/validators'

export default {
  name: 'WagerCreate',
  components: {
    GameHeader
  },
  mixins: [validationMixin],

  props: {
    game: Object,
    team_id: {
      type: [Number, String],
      default: 0
    },
    type: String,
    betlimit: {
      type: Number,
      default: 0
    },
    wager_id: {
      type: [Number, String],
      default: 0
    },
    steps: {
      type: Array,
      default: function () { return [10, 50, 100] }
    }
  },

  data: function () {
    return {
      loading: false,
      bettype_stepvalue: 0.5,
      bettype_name: null,
      stepvalue: this.steps[0],
      maxwager: 0,
      maxmoneyline: 0,
      vigSavings: 0,
      myteam: null,
      otherteam: null,
      groups: [],
      form: {
        game_id: this.game.id,
        offeredbyteam_id: this.team_id,
        friendgroup_id: null,
        offeredto_id: null,
        bettype_id: (this.type === 'line') ? 30 : (this.type === 'money') ? 10 : 20,
        offerto: 'anyone',
        amount: (this.betlimit < 10) ? this.betlimit : 10,
        lineamount: 0,
        fullwager: false
      },
      favorite: false,
      lineInfo: false,
      fullwagerInfo: false,
      userid: 0,
      VZBalace: 0,
      showDepositDialog: false,
      depositAmount: 0.00,
      honorbet: false,
      honorSystemChecked: false
    }
  },

  validations: {
    form: {
      game_id: {
        required,
        numeric
      },
      offeredbyteam_id: {
        required,
        numeric
      },
      bettype_id: {
        required,
        numeric
      },
      lineamount: {
        required
      },
      amount: {
        required,
        numeric
      },
      friendgroup_id: {
        required: requiredIf(function (form) {
          return (form && this.form.offerto === 'group' && this.groups && this.groups.length > 0)
        }),
        numeric
      }
    }
  },

  mounted: async function () {
    await this.getUserBalance()
    // Check if user has sufficient VZBalance
    if (this.VZBalace < 5) {
      this.showDepositDialog = true
    }
    // Set honorbet to true if there is no balance
    if (this.VZBalace === 0) {
      this.honorbet = true
      this.form.fullwager = true
    }
    this.init()
  },

  watch: {
    wagerIsValid: function (val) {
      if (val && this.form.amount < 10) {
        this.form.amount = 10
      }
    }
  },

  methods: {
    async init () {
      await this.loadWagerCreateInfo()
      this.form.lineamount = (this.type === 'line') ? this.game.ats_line : (this.type === 'money') ? this.game.money_line : this.game.ou_line
      this.myteam = (+this.team_id === +this.game.hometeam_id) ? this.game.hometeam : this.game.awayteam
      this.otherteam = (+this.team_id === +this.game.hometeam_id) ? this.game.awayteam : this.game.hometeam
      this.setStepValue(parseInt(WagerService.getWagerStep()) || this.steps[0])
      if (this.wager_id && this.wager_id !== 0) {
        this.setStepValue(this.game.amount || this.steps[0])
        this.form.amount = this.game.offeredbyamount
        this.form.fullwager = false
        this.form.followline = this.game.lineamount === null
        this.form.lineamount = this.form.followline ? this.game.currentline : this.game.lineamount
      }
      const user = UserService.getUserObject()
      if (user) {
        this.vigSavings = user.savings ? user.savings : 0
      }
      if (!this.wagerIsValid) {
        this.form.amount = 0
      }
    },

    async loadWagerCreateInfo () {
      this.$updateloading(1)
      await WagerService.createInit({ team_id: this.team_id }).then(
        (response) => {
          this.groups = []
          if (response.data.groups) {
            response.data.groups.forEach(element => {
              if (element.canoffer === 1) {
                this.groups.push(element)
              }
            })
          }

          if (response.data.fav_id) {
            this.favorite = true
          }

          response.data.bettypes.some((t) => {
            if (t.id === this.form.bettype_id) {
              this.bettype_stepvalue = t.stepvalue
              this.bettype_name = t.description
            }
          })

          this.maxwager = parseInt(response.data.max_wager_limit) || 0
          this.maxmoneyline = parseInt(response.data.max_money_line) || 0
        },
        (error) => {
          console.dir(error)
          this.$updateloading(-1)
        }
      ).finally(
        () => {
          this.$updateloading(-1)
        }
      )
    },

    async getUserBalance () {
      this.$updateloading(1)
      const user = UserService.getUserObject()
      this.userid = user ? +user.info.id : 0
      await TransactionService.getUserCurrentBalance(this.userid).then(
        (response) => {
          this.VZBalace = response.data
          console.log(this.VZBalace)
          if (this.VZBalace > 0) {
            this.honorbet = false
          }
        },
        (error) => {
          console.dir(error)
          this.$updateloading(-1)
        }
      ).finally(
        () => {
          this.$updateloading(-1)
        }
      )
    },

    async submitDeposit () {
      await this.getUserBalance()
      if (this.VZBalace <= 0) {
        if ((!this.depositAmount || this.depositAmount <= 0) && this.honorSystemChecked === false) {
          alert('Please either fund your account or select the honor system option')
          return
        }
      } else {
        if (!this.depositAmount || this.depositAmount <= 0) {
          alert('Please enter a valid amount')
          return
        }
      }

      if (this.honorSystemChecked === true) {
        this.makeOfferOnHonorSystem()
        return
      }

      this.$updateloading(1)

      const depositData = {
        user_id: this.userid, // Your user ID
        amount: this.depositAmount // The amount the user intends to deposit
      }

      try {
        // Send the deposit data to the backend, which returns the transaction ID
        const response = await TransactionService.addDeposit(depositData)
        // URLs for Venmo
        // const venmoURL = `venmo://pay?txn=pay&amount=${this.depositAmount}&note=VZ${response.data.transaction_id}`
        const webURL = `https://venmo.com/vigzero?txn=pay&amount=${this.depositAmount}&audience=private&note=VZ${response.data.transaction_id}`
        window.open(webURL, '_blank')
        // Check if the app is running as a PWA
        // const isPWA = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true
        // if (isPWA) {
        //   console.log('mobile')
        //   // Attempt to open the Venmo app if in PWA mode
        //   const iframe = document.createElement('iframe')
        //   iframe.style.display = 'none'
        //   iframe.src = venmoURL
        //   document.body.appendChild(iframe)
        //   // Fallback to the web URL if the Venmo app isn't opened
        //   setTimeout(() => {
        //     // document.body.removeChild(iframe)
        //     window.open(webURL, '_blank')
        //   }, 1500)
        // } else {
        //   console.log('not mobile')
        //   // Open the Venmo web URL if not in PWA mode
        //   window.open(webURL, '_blank')
        // }
      } catch (err) {
        console.error('Deposit error:', err)
        this.$notify({
          group: 'notices',
          title: 'Error',
          text: 'Failed to submit deposit request',
          type: 'danger'
        })
      } finally {
        this.depositAmount = 0
        this.closeDialog()
        this.$updateloading(-1)
      }
    },

    generateVenmoUrl (amount, transactionId) {
      const baseUrl = 'https://venmo.com/vigzero?txn=pay'
      const url = `${baseUrl}&amount=${amount}&audience=private&note=Deposit+ID+${transactionId}`
      return url
    },

    makeOfferOnHonorSystem () {
      this.form.fullwager = true
      this.honorbet = true
      this.closeDialog()
    },

    closeDialog: function () {
      this.showDepositDialog = false
    },

    async createWager () {
      const user = UserService.getUserObject()
      if (!user) {
        bus.$emit('notLoggedInModal')
        return
      }

      this.$updateloading(1)
      this.loading = true
      this.$v.$touch()
      await this.getUserBalance()
      if (this.$v.$invalid) {
        this.$updateloading(-1)
        this.$notify({
          group: 'errors',
          title: 'Invalid Submission',
          text: 'Please correct your form entries and try again.',
          type: 'warn'
        })
        this.loading = false
      } else if (this.VZBalace < this.form.amount && !this.honorbet) {
        this.showDepositDialog = true
        this.$updateloading(-1)
        this.loading = false
      } else {
        var data = Object.assign({}, this.form)
        data.honorbet = this.honorbet
        if (this.type === 'line') {
          if (data.lineamount !== this.game.ats_line) {
            data.followline = false
          }
        } else if (this.type === 'money') {
          if (data.lineamount !== this.game.money_line) {
            data.followline = false
          }
        } else if (data.lineamount !== this.game.ou_line) {
          data.followline = false
        }
        if (data.offerto === 'group') {
          // reset this to anyone if no group available
          if (!data.friendgroup_id) {
            data.offerto = 'anyone'
          }
        }
        await WagerService.create({ wager: data }).then(response => {
          this.$updateloading(-1)
          UserService.refreshAuthData()
          WagerService.setWagerStep(this.stepvalue)
          // on successful wager creation
          this.$gtag.event('create', {
            event_category: 'wager',
            event_label: 'created',
            value: data
          })
          this.loading = false
          this.$router.push('/dashboard/schedule/' + this.game.id, () => {
            this.$notify({
              group: 'notices',
              title: 'Offer/Wager Created',
              text: 'You will be notified when/if your offer is taken.',
              type: 'info',
              duration: 6000
            })
          })
        }).catch(errors => {
          this.$updateloading(-1)
          this.$notify({
            group: 'errors',
            title: (errors.response.data.errors) ? errors.response.data.errors[0] : 'Wager Failed',
            text: errors.response.data.error,
            type: 'warn'
          })
          this.loading = false
          console.dir(errors)
        })
      }
    },

    async updateWager () {
      this.$updateloading(1)
      this.loading = true
      this.$v.$touch()
      await this.getUserBalance()
      if (this.$v.$invalid) {
        this.$updateloading(-1)
        this.$notify({
          group: 'errors',
          title: 'Invalid Submission',
          text: 'Please correct your form entries and try again.',
          type: 'warn'
        })
        this.loading = false
      } else if (this.VZBalace < this.form.amount && !this.honorbet) {
        this.showDepositDialog = true
        this.$updateloading(-1)
        this.loading = false
      } else {
        var wager = {
          lineamount: this.form.lineamount,
          amount: this.form.amount,
          honorbet: this.honorbet
        }

        if (this.type === 'line') {
          if (wager.lineamount !== this.game.ats_line) {
            this.form.followline = false
          }
        } else if (wager.lineamount !== this.game.ou_line) {
          this.form.followline = false
        }

        if (this.form.followline) {
          wager.lineamount = null
        }

        var update = true
        await WagerService.getWager(this.wager_id).then(
          (response) => {
            if (response.data.takenby_id !== null) {
              this.$notify({
                group: 'notices',
                title: 'Could not update',
                text: 'Your offer was already taken and cannot be updated',
                type: 'info'
              })
              update = false
              this.$emit('update')
              this.$updateloading(-1)
              this.loading = false
            }
          }
        )

        if (update) {
          await WagerService.updateOffer(wager, this.wager_id).then(
            (response) => {
              this.$notify({
                group: 'notices',
                title: 'Successfully Updated',
                text: 'Your offer has been updated',
                type: 'info'
              })
              this.$emit('update')
              this.$updateloading(-1)
              this.loading = false
            }
          )
        }
      }
    },

    takerAmount (amt) {
      var ret = amt
      var line = this.form.lineamount
      if (this.form.bettype_id !== 10) {
        return amt
      }
      if (this.myteam === this.game.awayteam) {
        line = line * -1
      }
      if (line > 0) {
        // this is an underdog line
        ret = (line / 100) * amt
      } else {
        // this is a favorite line
        ret = (100 / line) * amt
      }
      return Math.round(Math.abs(ret))
    },

    setStepValue (value) {
      // we're potentially passed the value of the user's last wager,
      // so we need to make sure it's within our bounds
      this.stepvalue = this.steps[0]
      this.steps.some((s) => {
        if (value >= s) {
          this.stepvalue = s
        } else {
          return true
        }
      })
      // if the step value is greater than our current amount, update it
      if (this.stepvalue > this.form.amount) {
        this.form.amount = this.checkAmount(this.stepvalue)
        if (this.form.amount > this.betlimit) {
          this.form.amount = this.checkAmount(this.betlimit)
        }
        if (this.form.amount > this.maxwager && this.type !== 'money') {
          this.form.amount = this.checkAmount(this.maxwager)
        }
      }
    },

    stepLine (amount, min, max) {
      if (typeof min !== 'undefined' || typeof max !== 'undefined') {
        if (min && this.form.lineamount + amount < min) {
          this.form.lineamount = min
        } else if (max && this.form.lineamount + amount > max) {
          this.form.lineamount = max
        } else {
          this.form.lineamount += amount
        }
      } else {
        this.form.lineamount += amount
      }
    },

    async stepAmount (amount, min, max) {
      await this.getUserBalance()
      if (typeof min !== 'undefined' || typeof max !== 'undefined') {
        var amt = this.form.amount + amount
        if (min && amt < min) {
          amt = min
        }

        // If we're given a max, make sure it's less than our global max wager amount unless it's money line
        if (this.type !== 'money' && max > this.maxwager) {
          max = this.maxwager
        }

        if (max && amt > max && this.VZBalace <= 0) {
          amt = max
        }
        if (this.VZBalace <= 0) {
          this.form.amount = this.checkAmount(amt)
        } else {
          this.form.amount = amt
        }
      } else {
        if (this.VZBalace <= 0) {
          this.form.amount = this.checkAmount(this.form.amount + amount)
        } else {
          this.form.amount = this.form.amount + amount
        }
      }
      if (this.VZBalace < this.form.amount && !this.honorbet) {
        this.showDepositDialog = true
      }
    },

    checkAmount (amt) {
      if (this.type === 'money') {
        if (amt > this.betlimit) {
          return this.form.amount
        } else {
          return amt
        }
      } else {
        return amt
      }
    },

    setGroup (val) {
      if (val) {
        console.dir(val)
        this.form.friendgroup_id = val
      }
    },

    showSpread (line, team) {
      return (line === 0) ? 'PK' : GameService.showSpread(line, team)
    },

    async favoriteClicked () {
      const user = UserService.getUserObject()
      if (!user) {
        return
      }
      this.$updateloading(1)
      if (this.favorite) {
        await FavoriteService.remove(this.team_id).then(
          (response) => {
            if (response.data.success) {
              this.favorite = false
              this.$notify({
                group: 'notices',
                title: 'Favorite Removed',
                text: 'This team is no longer a favorite',
                type: 'info'
              })
            } else {
              this.$notify({
                group: 'errors',
                title: 'An error has occured',
                text: 'We were unable to remove the favorite at this time',
                type: 'warn'
              })
            }
          },
          (error) => {
            console.log(error)
            this.$notify({
              group: 'errors',
              title: 'An error has occured',
              text: 'We were unable to remove the favorite at this time',
              type: 'warn'
            })
          }
        ).finally(() => { this.$updateloading(-1) })
      } else {
        await FavoriteService.add(this.team_id).then(
          (response) => {
            console.log(response.data.fav_id)
            if (response.data.fav_id) {
              this.favorite = true
              this.$notify({
                group: 'notices',
                title: 'Favorite Added',
                text: 'Successfully added favorite',
                type: 'info'
              })
            } else {
              this.$notify({
                group: 'errors',
                title: 'An error has occured',
                text: 'We were unable to add the favorite at this time',
                type: 'warn'
              })
            }
          },
          (error) => {
            console.log(error)
            this.$notify({
              group: 'errors',
              title: 'An error has occured',
              text: 'We were unable to add the favorite at this time',
              type: 'warn'
            })
          }
        ).finally(() => { this.$updateloading(-1) })
      }
    },
    getFontSize: function (str) {
      var len = 1
      if (str < 0) {
        len = 0
      }
      len += (str + '').length

      var size = '1em'
      if (len > 3) {
        size = '0.8em'
      }
      if (len > 5) {
        size = '0.6em'
      }
      return size
    }
  },

  computed: {
    wagerIsValid: function () {
      var amt = this.form.amount
      if (amt < 10) {
        amt = 10
      }
      if (amt > this.betlimit && this.VZBalace <= 0) {
        console.log('invalid: amount less than limit')
        return false
      }
      if (this.maxmoneyline > 0 && (this.form.lineamount > this.maxmoneyline || this.form.lineamount < this.maxmoneyline * -1)) {
        console.log('invalid: money line exceeded')
        return false
      }
      if (amt < 10) {
        console.log('invalid: amount less than 10')
        return false
      }
      if (this.takerAmount(amt) < 10) {
        console.log('taker amount invalid')
        return false
      }
      return true
      // return (amt <= this.betlimit && amt >= 10 && this.form.lineamount <= 1000 && this.form.lineamount >= -1000)
      /* if (this.type === 'money') {
        return (amt <= this.betlimit && this.takerAmount(amt) <= this.betlimit && amt >= 10)
      } else {
        return (amt <= this.betlimit && amt >= 10)
      } */
    },

    wagerInvalidMessage: function () {
      if (!this.wagerIsValid) {
        var amt = this.form.amount
        if (amt < 10) {
          amt = 10
        }
        if (amt > this.betlimit) {
          return 'Not enough funds available'
        } else if (this.maxmoneyline > 0 && (this.form.lineamount > this.maxmoneyline || this.form.lineamount < this.maxmoneyline * -1)) {
          return 'Maximum money line value exceeded'
        } else if (this.takerAmount(amt) < 10 || amt < 10) {
          return 'Minimum Wager amount not met'
        }
      }
      return null
    },

    hasPaymentMethod: function () {
      const user = UserService.getUserObject()
      let paymentmethods = []
      if (user) {
        paymentmethods = user.paymentmethod
      } else {
        return true
      }
      if (paymentmethods.length > 0) {
        return paymentmethods[0].details[0].value !== ''
      } else {
        return false
      }
    },

    gameHeaderInfo: function () {
      return {
        league: this.game.league,
        sport: this.game.sport,
        gamedate: this.game.gamedate_formatted,
        starttime: this.game.starttime_formatted,
        currentline: (this.type === 'line') ? this.game.ats_line : (this.type === 'money') ? this.game.money_line : this.game.ou_line,
        awayteam: this.game.awayteamname,
        hometeam: this.game.hometeamname,
        bettype_id: (this.type === 'line') ? 30 : (this.type === 'money') ? 10 : 20
      }
    }
  }
}
</script>

<style scoped>
.v-dialog__overlay {
    background-color: rgba(0,0,0,0) !important; /* Completely transparent */
}
.v-dialog__content {
    box-shadow: 0px 0px 12px rgba(0,0,0,0.8); /* Optional: Adding shadow for better visibility */
    border: 1px solid rgba(0,0,0,0.1); /* Optional: Adding subtle border */
}
.md-icon-button {
  font-size: larger;
}
.md-icon-button.active {
  background: #4a992e !important;
  color: white !important;
}
.md-icon-button.inactive {
  background: #ededed !important;
}
.text-link {
    color: #E53935; /* Red darken-1 from Vuetify color palette */
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    background: none;
    border: none;
    display: inline;
}
.text-link:hover {
    text-decoration: none; /* Change as per your design needs */
}
</style>
