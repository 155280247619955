var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-carousel",
        {
          staticClass: "carousel",
          attrs: { "show-arrows": false, height: "90vh" },
          model: {
            value: _vm.currentslide,
            callback: function($$v) {
              _vm.currentslide = $$v
            },
            expression: "currentslide"
          }
        },
        [
          _c(
            "v-carousel-item",
            [
              _c(
                "v-card",
                { attrs: { color: "white", tile: "", height: "90%" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "ma-0 pa-1",
                      staticStyle: { height: "10%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "heading white--text my-0 mx-auto slides-background-red py-2"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "text-center headline robo mb-0",
                              staticStyle: { "font-weight": "600" }
                            },
                            [_vm._v("Welcome to VigZero!")]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "px-4 py-0 my-0",
                      staticStyle: { height: "90%" }
                    },
                    [
                      _c("div", { staticStyle: { height: "100%" } }, [
                        _c("img", {
                          staticStyle: {
                            "max-height": "100%",
                            width: "100%",
                            "object-fit": "contain"
                          },
                          attrs: { src: "/img/slides/slide_1.png" }
                        })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-carousel-item",
            [
              _c(
                "v-card",
                { attrs: { color: "white", tile: "", height: "90%" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "ma-0 pa-1",
                      staticStyle: { height: "10%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "heading white--text my-0 mx-auto slides-background-red py-2"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "text-center headline robo mb-0",
                              staticStyle: { "font-weight": "600" }
                            },
                            [_vm._v("How To Create A Bet")]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "px-4 py-0 my-0",
                      staticStyle: { height: "90%" }
                    },
                    [
                      _c("div", { staticStyle: { height: "100%" } }, [
                        _c("img", {
                          staticStyle: {
                            "max-height": "100%",
                            width: "100%",
                            "object-fit": "contain"
                          },
                          attrs: { src: "/img/slides/slide_2.png" }
                        })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-carousel-item",
            [
              _c(
                "v-card",
                { attrs: { color: "white", tile: "", height: "90%" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "ma-0 pa-1",
                      staticStyle: { height: "10%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "heading white--text my-0 mx-auto slides-background-red py-2"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "text-center headline robo mb-0",
                              staticStyle: { "font-weight": "600" }
                            },
                            [_vm._v("How To Create A Bet")]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "px-4 py-0 my-0",
                      staticStyle: { height: "90%" }
                    },
                    [
                      _c("div", { staticStyle: { height: "100%" } }, [
                        _c("img", {
                          staticStyle: {
                            "max-height": "100%",
                            width: "100%",
                            "object-fit": "contain"
                          },
                          attrs: { src: "/img/slides/slide_3.png" }
                        })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div"),
          _c(
            "v-btn",
            {
              staticClass: "skip",
              attrs: { text: "", "x-large": "" },
              on: {
                click: function($event) {
                  return _vm.closeintroslide()
                }
              }
            },
            [
              _vm.lastSlide
                ? _c("span", [_vm._v("close")])
                : _c("span", [_vm._v("skip")])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }